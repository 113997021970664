.video-player-input {

    md-input-container:first-child {
        margin-right: 20px;
    }

    .md-errors-spacer {
        min-height: 0 !important;
    }

.vertical-label{   
    width: 55%;
    display: flex;
    justify-content: flex-end;
}

.vertical-video-inputs{
    display: flex;
    justify-content: space-between;
    height: 50px;
    margin: 15px 0;
    width: 800px;
}
}