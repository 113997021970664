.dashboard-graph {
  //Hide gridlines
  .tick line {
    display: none;
  }

  //Show the X axis
  .nvd3 .nv-axis.nv-x path.domain {
    stroke-opacity: 1;
  }

  //Change font of ticks and legend
  .nvd3 text {
    font: normal 14px Lato !important;
  }

  //Change legend font size
  .nv-series text {
    font-size: 16px !important;
  }

  //Make edge ticks bold
  .nv-axisMaxMin text {
    font-weight: bold !important;
  }

  //Change tooltip font
  .nvtooltip {
    font-family: Lato !important;
  }
}
