.ape-navbar {
  top: 64px;
  height: 44px;
  background: white;
  border-bottom: 1px solid #ebebeb;
  z-index: 1;
  padding: 8px 0;
}

.ape-navbar .product-tabs {
    float: left;

    li {
      .bordered {
        border-radius: 10px;
        border: 1px solid #E5E5E5;
        border-left: 0;
        border-right: 0;
        border-bottom: 0;
        padding: 5px 0 0;

        span {
          margin: -15px 15px 0;
          padding: 0 5px;
          position: absolute;
          background: white;
          color: #BABABA;
          font-size: 12px;
        }
      }
    }
}

.ape-navbar .product-tabs a {
  cursor: pointer;
  padding: 0 15px;
  line-height: 2;
  text-decoration: none;
  font-size: 16px;
  color: rgba(35, 31, 32, 0.6);
  font-weight: 400;
}


.ape-navbar .product-tabs a.active {
  opacity: 1;
  color: #231f20;
}

.selected-nav {
  position: relative;
  background: #ee2e3d;
  height: 3px;
  border-radius: 2px;
}

.selected-nav.campaigns {
    left: 106px;
    width: 106px;
}