.text-wrapper {
  position: relative;
  display: flex;
  textarea {
    background: $white;
    color: #68696B;
    font-size: 13px;
    width: 85%;
    resize: none;
    border: none;
    padding: 14px 17px 14px 17px;
    overflow-y: auto;
    overflow-x: hidden;
    word-break: break-all;
  }
  button {
    background: $white;
    @include box-shadow(0 0 10px 0 rgba(0, 0, 0, 0.20));
    border-radius: 2px;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    padding: 17px 17px;
    outline: none;
    border: none;
    margin-left: 5px;
    cursor: pointer;
    width: 15%;
  }
}