.dashboard {
  width: 75%;
  margin: auto;

  &.container {
    width: 90%;
  }

  .daily-data {
    margin-bottom: 30px;
    background: white;
    padding: 8px;
    border-radius: 4px;
    border-color: #EBEBEB;
    border-width: 1px;
    border-style: solid;
    // float: right;
    margin-left: auto;
    margin-right: 0;
    width: 45%;
  }

  .notifications {
    margin-right: 50px;
    width: 33%;

    ul {
      list-style-type: none;
      padding-left: 5px;
    }

    li {
      padding: 10px 15px;
      background: white;
      width: 100%;
      border: solid 1px #ebebeb;
      margin-top: -1px; //simulate border-collapse
    }
  }

  .daily-spent {
    ul {
      display: flex;
      list-style-type: none;
      padding-left: 5px;
    }

    li {
      margin-right: 40px;

      h4 {
        margin: 5px 0 0;
        color: #A8A7A7;
        font-size: 15px;
      }

      h5 {
        margin: 9px 0 0;
        width: 12px;
        height: 8px;
        background-color: $apester;
        mask-image: url("/svg/up.svg");
        -webkit-mask-repeat: no-repeat;
        -webkit-mask-position: center;
        -ms-transform: rotate(180deg); /* IE 9 */
        -webkit-transform: rotate(180deg); /* Chrome, Safari, Opera */
        transform: rotate(180deg);
      }

      div {
        font-size: 22px;
        font-weight: 600;
      }

      span {
        color: #A8A7A7;
        font-size: 13px;
      }
    }
  }
}
