.navbar {
  .white-modal {
    height: 100%;
    width: 100%;
    visibility: hidden;
    position: fixed;
    background: rgba($white, 0);
    z-index: 1;
    //@include transition(.2s);
    transition: .2s;

    &.open {
      background: rgba($white, .9);
      visibility: visible;

      .engines-container .create {
        top: 0;
        opacity: 1;
      }

      i.close {
        color: $medium-grey;
      }
    }

    i.close {
      position: absolute;
      right: 27px;
      top: 27px;
      font-size: 24px;
      cursor: pointer;
      color: rgba($medium-grey, 0);
      //@include transition(.2s);
      transition: .2s;

      &:hover {
        color: $deep-grey;
      }
    }

    .engines-container {
      position: relative;
      float: left;
      top: 50%;
      left: 50%;
      //@include transform(translateX(-50%) translateY(-50%));
      //-webkit-transform: translateX(-50%) translateY(-50%);
      //-moz-transform: translateX(-50%) translateY(-50%);
      //-ms-transform: translateX(-50%) translateY(-50%);
      //-o-transform: translateX(-50%) translateY(-50%);
      transform: translateX(-50%) translateY(-50%);

      .create {
        float: left;
        margin: 0 50px;
        color: $white;
        cursor: pointer;
        text-align: center;
        top: -600px;
        position: relative;
        @include opacity(0);
        //@include transition(.5s cubic-bezier(0.68, -0.55, 0.265, 1.55));
        //-webkit-transition: .5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
        //-moz-transition: .5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
        //-ms-transition: .5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
        //-o-transition: .5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
        transition: .5s cubic-bezier(0.68, -0.55, 0.265, 1.55);

        &.small {
          position: absolute;
          margin: 0;
          top: 60px;
          left: 25px;
          z-index: -1;

          span {
            font-size: 14px;
          }

          .circle {
            width: 50px;
            line-height: 50px;
            font-size: 25px;
            margin: 10px 0 0;
          }
        }

        span {
          font-size: 19px;
          font-weight: 400;
        }

        .circle {
          width: 100px;
          line-height: 100px;
          display: block;
          font-size: 50px;
          margin: 10px 0;
          //@include border-radius(50%);
          border-radius: 50%;
          background-color: $apester;

          .branded {
            width: 100%;
            height: 100%;
            background-color: white;
            mask-image: url("/svg/tag.svg");
            -webkit-mask-repeat: no-repeat;
            -webkit-mask-position: center;
          }

          .video {
            width: 100%;
            height: 100%;
            background-color: white;
            mask-image: url("/svg/vid.svg");
            -webkit-mask-repeat: no-repeat;
            -webkit-mask-position: center;
          }

          .companion {
            width: 100%;
            height: 100%;
            background-color: white;
            mask-image: url("/svg/companion.svg");
            -webkit-mask-repeat: no-repeat;
            -webkit-mask-position: center;
          }
        }

        &.rolling-story {
          //@include transition-delay(.3s);
          transition-delay: .3s;
          span {
            font-weight: 600;
            color: $journey;
          }
          .circle {
            background: $journey;
          }
        }

        &.poll {
          //@include transition-delay(.05s);
          transition-delay: .05s;
          span {
            font-weight: 600;
            color: $poll;
          }
          .circle {
            background: $poll;
          }
        }

        &.quiz {
          //@include transition-delay(.1s);
          transition-delay: .1s;
          span {
            font-weight: 600;
            color: $quiz;
          }
          .circle {
            background: $quiz;
          }
        }

        &.personality {
          //@include transition-delay(.15s);
          transition-delay: .15s;
          span {
            font-weight: 600;
            color: $personality;
          }
          .circle {
            background: $personality;
          }
        }

        &.contest-poll {
          //@include transition-delay(.25s);
          transition-delay: .25s;
          span {
            font-weight: 600;
            color: $contest-poll;
          }
          .circle {
            background: $contest-poll;
          }
        }
        &.type {
          //@include transition-delay(.25s);
          transition-delay: .25s;
          span {
            color: $apester;
          }
          a {
            color: #7E7F7F;
            text-decoration: none;
          }
          .circle {
            width: 100px;
            height: 100px;
            // background: $apester;
          }
        }


        .scale {
          @include transition(.2s ease-out);
          width: 100px;
          &:hover {
            @include scale(1.2);
          }
        }
      }

      > .create {

        &.video {
          //@include transition-delay(.2s);
          transition-delay: .2s;

          .small {
            visibility: hidden;
          }

          &:hover .small {
            visibility: visible;
            top: 182px;

            &.poll {
              left: -50px;
              top: 162px;
            }

            &.quiz {
              left: 25px;
            }

            &.personality {
              left: 92px;
              top: 162px;
            }
          }
        }

        &.video {
          span {
            color: $video;
          }

          .circle {
            background: $video;
            height: 100px;
            width: 100px;
          }

          .icon-personality {
            position: relative;
            left: 8px;
          }

        }

      }
    }
  }
}
