.input-line {
  display: flex;
  flex-direction: row;

  .input-line-title {
    white-space: nowrap;
    font-weight: bold;
    position: relative;
    top: 25px;
    min-width: 120px;
  }

  .input-line-input {
    min-width: 350px;
    margin-left: 10px;
  }

  &.indent-left {
    margin-left: 100px;
  }

  &.no-indent {
    margin-left: 0;
  }
}

