.main {
  padding: 0 !important;
}

.injector {
  width: 90%;
}

.header-container {
  background-color: $white;
  height: 153px;
  padding-left: 6%;
  margin: 0 auto 20px;
  list-style-type: none;
  .icon {
    float: left;
    width: 44px;
    height: 44px;
    background: #FFCD00;
    border-radius: 50%;
    margin-top: 44px;
    span {
      line-height: 44px;
      padding: 2px 11px;
      font-size: 22px;
      color: $white;
    }
  }
  .header {
    float: left;
    margin: 34px 10px;
    .title {
      font-size: 28px;
      font-weight: bold;
      color: #252424;
      letter-spacing: 0;
      margin-bottom: 0;
    }
    .subtitle {
      font-size: 14px;
      font-weight: bolder;
      color: #FFCD00;
      letter-spacing: 0;
    }
  }
}

.injector {
  .search-container {
    width: 345px;
    background-color: $white;
    border-radius: 5px;
    max-height: 60px;
    margin: 30px 0 40px;
  }
  .injector-table {
    width: 100%;
    margin-bottom: 60px;
    a {
      text-decoration: none;
    }
    md-select {
      margin: 0 10px 0 0;
    }
    md-select:not([disabled]):focus .md-select-value, md-select .md-select-value ,md-select .md-select-value.md-select-placeholder {
      border-bottom-color: transparent;
      span:not(.md-select-icon) {
        color: rgba(33,33,33,0.87);
      }
      .md-select-icon {
        color: rgba(0,0,0,0.38);
      }
    }
    md-input-container {
      margin: auto 0;
      padding: 20px 0;
      height: 70px;
      input {
        border-color: transparent;
      }
    }
    thead {
      background: #252424;
      color: white;
    }
    th {
      padding: 20px;
      white-space: nowrap;
      font-size: 14px;
      font-weight: 400;
      span {
        cursor: pointer;
        vertical-align: middle;
        font-size: 8px;
        margin: 0 0 0 10px;
        &.rotated {
          display: inline-block;
          transform: rotate(180deg);
        }
      }
    }
    th:first-child {
      padding-left: 15px;
      border-top-left-radius: 5px;
    }
    th:last-child {
      border-top-right-radius: 5px;
    }
    .data-channel,
    .data-date {
      width: 14% !important;
      text-align: center;
    }
    .data-unit,
    .data-url {
      width: 10% !important;
      text-align: center;
    }
    .data-action {
      width: 5% !important;
    }
    tr:not(:last-child) {
      border-bottom: 1px solid #ebebeb;
    }
    tr:hover td {
      background: #f2f2f2;
    }
    .thumb-image {
      padding: 0;
      .background {
        position: relative;
        width: 100%;
        min-width: 90px;
        height: 100%;
        margin: 0 auto;
        display: block;
      }
    }
    td {
      height: 50px;
      background: white;
      padding: 0 0 0 16px;
    }
    td:first-child {
      padding-left: 17px;
      max-width: 56px;
    }
    td:not(:last-child) {
      border-right: 1px solid #ebebeb;
    }
    .inject-action {
      padding: 0 0 0 10px !important;
      cursor: default;
      span {
        color: #B9C0CB;
        position: absolute;
        margin: -7px 20px;
        transition: margin-top 0.3s ease-out;
      }
      label {
        font-size: 11px;
        color: #A2A9B6;
        letter-spacing: 0;
        visibility: hidden;
        margin: 5px 15px 0;
        position: absolute;
        opacity: 0;
        transition: margin-top 0.3s ease-out, opacity 0.3s ease-out;
      }
      .error-label {
        position: relative;
        visibility: visible;
        opacity: 1;
        color: #DD2C00;
        margin: 8px 12px 0;
      }
      &.active {
        cursor: pointer;
        span {
          cursor: pointer;
        }
        &:hover {
          span {
            margin-top: -11px;
          }
          label {
            opacity: 1;
            visibility: visible;
            margin-top: 8px;
          }
        }
        .icon-restart {
          &.update {
            color: #1EDD8E;
          }
        }
        &:hover .icon-restart {
          color: #3B3E44;
          &.update {
            color: #1EDD8E;
          }
        }
        &:hover .icon-approve2 {
          color: #1EDD8E;
        }
        &:hover .icon-cross2 {
          color: #EE2E3D;
        }
      }
    }
  }
  .action-item {
    color: #a7a7a7;
    padding-left: 25px;
    font-size: 18px;
    &:hover {
      color: #a7a7a7;
    }
    &.md-focused {
      color: #a7a7a7;
    }
    i {
      margin-right: 10px;
      &.icon-cross {
        color: #eb3243;
      }
    }
  }
  .pagination {
    margin-top: 0;
    float: right;
    li a {
      color: #333333;
      background-color: #ffffff;
      border: 1px solid #ddd;
    }
    .active {
      a,
      a:hover,
      a:focus {
        color: #BABABA;
        background-color: #ffffff;
        border: 1px solid #ddd;
      }
    }
  }
  .pagination-options {
    float: right;
    margin: 0 20px;
  }
}

.inject-form {
  margin: 40px auto 20px;
  padding-left: 0;
  list-style-type: none;
  md-select {
    float: left;
    width: 15%;
    height: 59px;
    margin: -17px 20px 0 37px;
  }
  md-input-container {
    float: left;
    margin: 0 0 0 30px;
    width: 14%;
    height: 45px;
    input {
      height: 100%;
    }
  }
  .inject-insert {
    width: 8%;
    float: right;
    height: 40px;
    margin: 0 15px;
    .inject-button {
      width: 100%;
      background-color: #1EDD8E;
      border-color: #1EDD8E;
      border-radius: 4px;
      margin: 4px 30px 0 0;
      span {
        margin: 0 10px;
      }
    }
    .inject-message {
      font-size: 12px;
      opacity: 1;
      &.success {
        transition: opacity .3s 5s ease-in-out;
        opacity: 0;
      }
    }
  }
  .inject-details
  {
    margin-top: 50px;
    margin-left: 0px;

  }
}

.inject-actio {
  width: 70px;
}

.channel-header {
  width: 180px;
  max-width: 180px;
}

.channel-search {
  width: 100%;
  height: 45px;
  border: 0;
  border-bottom: 1px solid grey;
  padding-left: 10px;
}
