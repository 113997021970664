md-radio-button .md-off {
  transform: scale(0.5);
}

.inline-radios {
  display: inline;
  .inline-checkbox {
    display: inline-block;
    margin-right: 46px;
    margin-bottom: 16px;
  }
  md-radio-button {
    display: inline-block;
    margin-right: 10px;
  }
  &.not-editable {
    *{
      cursor: default;
    }   
    .md-container {
      display: none;
    }
  }
}
