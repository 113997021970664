// COLORS
$apester:           #ee2e3d !default;
$white:               white !default;
$black:               black !default;
$transparent:   transparent !default;
$dark-grey:         #252424 !default;
$dark-grey-text:    #231f20 !default;
$deep-grey:         #7e7f7f !default;
$medium-grey:       #a8a7a7 !default;
$soft-grey:         #f7f7f7 !default;
$light-grey:        #ebebeb !default;
$deepest-gray:         #444 !default;


// ENGINES
$poll:              #ee9802 !default;
$quiz:              #8fd100 !default;
$correct:           #467b61 !default;
$personality:       #d61edd !default;
$video:             #24dfd0 !default;
$contest-poll:      #ffcd00 !default;
$journey:           #579AFF !default;

// OTHERS
//$twitter:           #55acee !default;
//$facebook:          #3b5998 !default;
//$settings:          #1edd8e !default;
//$ape-blue:          #2b74f9 !default;

// CAMPAIGN MANAGER COLORS
$btn-primary:       #35decf !default;
$input:             #1EDD8E !default;
$panel-number:      #939FB2 !default;
$panel-head:        #B9C0CB !default;

// AB TEST
$btn-run:               #1fba75 !default;

$icomoon-font-family: "Apester-Campaing-Font" !default;
$icomoon-font-path: "/fonts" !default;

$icon-random: unquote('"\\e93d"');
$icon-confetti: unquote('"\\e93b"');
$icon-star: unquote('"\\e93c"');
$icon-intro-arrow: unquote('"\\e93a"');
$icon-thumb: unquote('"\\e939"');
$icon-hamburger: unquote('"\\e937"');
$icon-upload: unquote('"\\e934"');
$icon-interaction: unquote('"\\e932"');
$icon-snippet: unquote('"\\e933"');
$icon-tags: unquote('"\\e92f"');
$icon-amp: unquote('"\\e926"');
$icon-info: unquote('"\\e924"');
$icon-share: unquote('"\\e91d"');
$icon-like: unquote('"\\e91e"');
$icon-split: unquote('"\\e91f"');
$icon-add-collage: unquote('"\\e920"');
$icon-text-rtl: unquote('"\\e929"');
$icon-text-ltr: unquote('"\\e92a"');
$icon-text-j: unquote('"\\e92b"');
$icon-text-ar: unquote('"\\e92c"');
$icon-text-ac: unquote('"\\e92d"');
$icon-text-al: unquote('"\\e92e"');
$icon-text-bg: unquote('"\\e927"');
$icon-text-l: unquote('"\\e912"');
$icon-copy-link: unquote('"\\e912"');
$icon-text-q: unquote('"\\e913"');
$icon-text-u: unquote('"\\e928"');
$icon-text-i: unquote('"\\e914"');
$icon-text-b: unquote('"\\e915"');
$icon-text-h: unquote('"\\e916"');
$icon-add-embed: unquote('"\\e917"');
$icon-add-image: unquote('"\\e911"');
$icon-summary: unquote('"\\e918"');
$icon-link: unquote('"\\e919"');
$icon-chapter: unquote('"\\e91a"');
$icon-question: unquote('"\\e91b"');
$icon-fullscreen: unquote('"\\e910"');
$icon-dataroom: unquote('"\\e90e"');
$icon-suggestions: unquote('"\\e904"');
$icon-print: unquote('"\\e908"');
$icon-edit-pencil: unquote('"\\e90b"');
$icon-password: unquote('"\\e90c"');
$icon-alert: unquote('"\\e90d"');
$icon-minus: unquote('"\\e91c"');
$icon-check: unquote('"\\f00c"');
$icon-download: unquote('"\\e905"');
$icon-view: unquote('"\\e90f"');
$icon-views: unquote('"\\e90f"');
$icon-edit: unquote('"\\e907"');
$icon-archive: unquote('"\\e909"');
$icon-results: unquote('"\\e921"');
$icon-duplicate: unquote('"\\e90a"');
$icon-refresh: unquote('"\\e901"');
$icon-save: unquote('"\\e085"');
$icon-url: unquote('"\\e902"');
$icon-computer: unquote('"\\e611"');
$icon-focus-layout: unquote('"\\e930"');
$icon-versus-layout: unquote('"\\e925"');
$icon-image-layout: unquote('"\\e612"');
$icon-text-layout: unquote('"\\e61b"');
$icon-background: unquote('"\\e610"');
$icon-undo: unquote('"\\e62c"');
$icon-restart: unquote('"\\e629"');
$icon-feedback-check: unquote('"\\e626"');
$icon-feedback-plus: unquote('"\\e627"');
$icon-generate: unquote('"\\e624"');
$icon-search: unquote('"\\e623"');
$icon-key: unquote('"\\e622"');
$icon-iframe: unquote('"\\e62a"');
$icon-embed: unquote('"\\e4ea"');
$icon-html5: unquote('"\\eadf"');
$icon-website: unquote('"\\f19b"');
$icon-wordpress: unquote('"\\f19a"');
$icon-audio: unquote('"\\e935"');
$icon-trimmer: unquote('"\\e938"');
$icon-pause: unquote('"\\e936"');
$icon-play: unquote('"\\e614"');
$icon-trash: unquote('"\\e616"');
$icon-trash-2: unquote('"\\e616"');
$icon-circle-cross: unquote('"\\e043"');
$icon-circle-check: unquote('"\\e042"');
$icon-duration: unquote('"\\f017"');
$icon-tag: unquote('"\\e61f"');
$icon-arrow-left: unquote('"\\e61c"');
$icon-arrow-down: unquote('"\\e615"');
$icon-arrow-up: unquote('"\\e618"');
$icon-arrow-right: unquote('"\\e61d"');
$icon-plus: unquote('"\\e115"');
$icon-approve: unquote('"\\e119"');
$icon-cross: unquote('"\\e11a"');
$icon-layers: unquote('"\\e60d"');
$icon-inventory: unquote('"\\e603"');
$icon-layouts: unquote('"\\e605"');
$icon-cnet: unquote('"\\e62b"');
$icon-huff: unquote('"\\e625"');
$icon-email: unquote('"\\e922"');
$icon-share-embed: unquote('"\\e923"');
$icon-instagram: unquote('"\\e931"');
$icon-messenger: unquote('"\\e93e"');
$icon-youtube: unquote('"\\e4a0"');
$icon-google-plus: unquote('"\\e4a1"');
$icon-linkedin: unquote('"\\e49f"');
$icon-facebook: unquote('"\\e49e"');
$icon-twitter: unquote('"\\e49d"');
$icon-pinterest: unquote('"\\e4d6"');
$icon-whatsapp: unquote('"\\e608"');
$icon-rolling-story-round: unquote('"\\e903"');
$icon-countdown-round: unquote('"\\e93f"');
$icon-video-round: unquote('"\\e628"');
$icon-personality-round: unquote('"\\e61a"');
$icon-quiz-round: unquote('"\\e619"');
$icon-poll-round: unquote('"\\e613"');
$icon-apester-round: unquote('"\\e607"');
$icon-royal-a-round: unquote('"\\e607"');
$icon-rolling-story: unquote('"\\e60f"');
$icon-countdown: unquote('"\\e604"');
$icon-video: unquote('"\\e609"');
$icon-personality: unquote('"\\e60a"');
$icon-quiz: unquote('"\\e60b"');
$icon-poll: unquote('"\\e60c"');
$icon-apester-a: unquote('"\\e606"');
$icon-royal-a: unquote('"\\e606"');
$icon-apester-logo: unquote('"\\e60e"');
$icon-sort_arrow: unquote('"\\e941"');
$icon-inject: unquote('"\\e940"');
$icon-more: unquote('"\\e900"');
$icon-edit2: unquote('"\\e906"');
$icon-plus2: unquote('"\\e114"');
$icon-approve2: unquote('"\\e118"');
$icon-cross2: unquote('"\\e117"');

