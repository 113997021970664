.companion-yield-strategies {
  .display-wrapper {
    border: solid 1px rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    position: relative;
    width: 100%;
    padding: 30px;

    .header {
        position: absolute;
        z-index: 1;
        top:-15px; left: -1px;
        padding-right: 10px;
        background-color: white;
        font-weight: bold;
        font-size: 18px;
    }
  }
  .collapsable-section:not(:last-child) {
    margin-bottom: 30px;
  }

  .outside-options-container {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    .outside-options {
      display: flex;
      margin-left: 5%;
      margin-top: 10px;

      .video-options {
        display: flex;
        flex-direction: column-reverse;

        &.inline-radios {
          max-width: 70%;
          margin-right: 20px;
        }

        md-checkbox {
          margin-right: 20px;
        }
      }
      
      .aniviw-player {
        margin-left: 130px;
      }

      .input-line {
        display: inline-block;
      }
    }
  }

  .header-bidding-locations > div {
    margin: 0 0 50px 50px;
    width: 50vw;

    .input-line .input-line-title {
      top: 0;
    }
  }
}
