*:focus {
  outline: none;
}

body,
a,
button,
input,
select,
textarea {
  font-family: 'Lato', "Meoded Sans", sans-serif;
  font-weight: 400;
  font-size: 16px;
}

html {
  height: 100%; width:100%;
  background: none;
}

body {
  background: $soft-grey;
  height: 100%; width:100%;
}

body > div.main {
  position: relative;
  padding-top: 100px;
  padding-bottom: 40px;
}

button.ic {
  background: none;
  border: none;
}

@mixin push-right-classes {
  // using '!important' to override pusedo-classes (e.g.:last-child). if it breaks your design, just don't use this class.
  @for $i from 10 through 100 {
    .push-right-#{$i} { margin-left: #{$i + 'px !important'}; }
  }
}

@include push-right-classes;

@mixin push-left-classes {
  // using '!important' to override pusedo-classes (e.g.:last-child). if it breaks your design, just don't use this class.
  @for $i from 10 through 100 {
    .push-left-#{$i} { margin-right: #{$i + 'px !important'}; }
  }
}

@include push-left-classes;

.full-width {
  width: 100%;
}

.flex-start-wrapper {
  display: flex;
  justify-content: flex-start;
}

.flex-end-wrapper {
  display: flex;
  justify-content: flex-end;
}

.flex-between-wrapper {
  display: flex;
  justify-content: space-between;
}

.flex-evenly-wrapper {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.align-center {
  display: flex;
  align-items: center;
}

.justify-center {
  display: flex;
  justify-content: center;
}

.error {
  color: red;
}

select.uppercase {
  text-transform: uppercase;
}
select.capitalize {
  text-transform: capitalize;
}
