.player-viewability-event-input {
  &.inline {
    display: inline-flex;
    > .select-label {
      white-space: nowrap;
      min-width: 120px;
    }
    > .select-box {
      min-width: 120px;
      margin-left: 10px;
      order: 0;
    }
  }
  > .select-label {
    padding-top: 1.5%;
    width: 45%;
  }
  > .select-box {
    order: 0;
    width: 42%;
  }

  .onload-extra-options {
    margin-left: 20px;
    min-width: 140px;
    > input {
      min-width: 140px !important;
      padding-left: 0 !important;
      margin-top: 0 !important;
      margin-left: 0 !important;
      height: unset !important;
    }
    .md-errors-spacer {
      display: none;
    }
  }
}
