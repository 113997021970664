.navbar {
  background: $white;
  border-color: $light-grey;
  z-index: 2;

  &.single {
    height: 77px;
  }

  .container {
    height: 44px;
    width: 75%;

    a.logo {
      margin-top: 12px;
      width: 52px;
      height: 52px;
      float: left;
      background-image: url("/svg/ape-icon.svg");
      background-repeat: no-repeat;
      //@include background-size(52px 52px);
      transition: .2s;
      opacity: 1;

      &:hover {
        @include opacity(.8);
      }
    }

    .site-title {
      display: inline-block;
      margin-left: 16px;
      margin-top: 26px;
      text-transform: capitalize;
    }

    .create {
      line-height: 38px;
      float: right;
      margin: 19px 15px 0 62px;
      color: $white;
      position: relative;
      padding: 1px;
      text-align: right;
      text-decoration: none;
      transition: .2s;
      opacity: 1;
      border-radius: 19px;
      border: none;
      height: 38px;

      &:before {
        content: '';
        position: absolute;
      }

      .icon {
        border-radius: 19px 0 0 19px;
        border: none;
        background: $apester;
        width: 38px;
        padding-top: 2px

        i {
          font-weight: bold;
          font-size: 14px;
        }
      }

      .separator {
        color: $apester;
        padding: 0;
      }

      .campaign {
        cursor: pointer;

        &:hover {
          background-color: $apester;
          transition: background-color 0.5s linear;
        }
      }

      .creative {
        cursor: pointer;
        border-radius: 0 19px 19px 0;
        border: none;
        padding:0 15px 0 10px;

         &:hover {
          background-color: $apester;
          transition: background-color 0.5s linear;
        }
      }

      li {
        font-size: 13px;
        display: inline;
        background: #D92735;
        color: $white;
        height: 38px;
        overflow:auto;
        padding: 0 10px;
        list-style-type: none;
        text-align: center;
        float: left;
      }
    }

    .user-panel {
      float: right;
      height: 100%;
      position: relative;

      .profile-picture-container {
        border-right: 1px solid $transparent;
        border-left: 1px solid $transparent;
        float: right;
        height: 100%;
        background: $white;

        &.open {
          border-color: $light-grey;
          @include calc(height, '100% + 1px');
        }

        .profile-picture {
          margin: 19px 14px 0;
          border: 2px solid $medium-grey;
          height: 38px;
          width: 38px;
          background-position: center;
          //@include background-size(cover);
          border-radius: 50%;
        }
      }

      ul.user-container {
        width: 150px;
        display: none;

        li {
          background: $white;
          color: $medium-grey;
          font-size: 16px;
          float: left;
          width: 100%;
          line-height: 48px;
          padding: 0 10px 0 15px;
          cursor: pointer;
          border: 1px solid $light-grey;
          border-top: none;
          transition: .2s;

          &:hover {
            padding: 0 10px 0 18px;
            color: $deep-grey;
          }
        }

        a {
          text-decoration: none;
          color: $medium-grey;
          font-size: 16px;

          &:hover {
            color: $deep-grey;
          }

        }

        &.open {
          display: block;
          position: absolute;
          top: 77px;
          right: 0px;
        }
      }
    }
  }

  .white-modal {
    height: 100%;
    width: 100%;
    visibility: hidden;
    position: fixed;
    background: rgba($white, 0);
    z-index: 1;
    @include transition(.2s);

    &.open {
      background: rgba($white, .9);
      visibility: visible;

      .engines-container .create {
        top: 0;
        // @include opaque;
      }

      i.close {
        color: $medium-grey;
      }
    }

    i.close {
      position: absolute;
      right: 27px;
      top: 27px;
      font-size: 24px;
      cursor: pointer;
      color: rgba($medium-grey, 0);
      @include transition(.2s);

      &:hover {
        color: $deep-grey;
      }
    }

    .engines-container {
      position: relative;
      float: left;
      top: 50%;
      left: 50%;
      // @include transform(translateX(-50%) translateY(-50%));

      .create {
        float: left;
        margin: 0 50px;
        color: $white;
        cursor: pointer;
        text-align: center;
        top: -600px;
        position: relative;
        @include opacity(0);
        //@include transition(.5s cubic-bezier(0.68, -0.55, 0.265, 1.55));

        &.small {
          position: absolute;
          margin: 0;
          top: 60px;
          left: 25px;
          z-index: 1;

          span {
            font-size: 14px;
          }

          .circle {
            width: 50px;
            line-height: 50px;
            font-size: 25px;
            margin: 10px 0 0;
          }
        }

        span {
          font-size: 19px;
          font-weight: 400;
        }

        .circle {
          width: 100px;
          line-height: 100px;
          display: block;
          font-size: 50px;
          margin: 20px 0 0;
          @include border-radius(50%);
        }

        &.rolling-story {
          @include transition-delay(.3s);
          span {
            color: $journey;
          }
          .circle {
            background: $journey;
          }
        }

        &.poll {
          @include transition-delay(.05s);
          span {
            color: $poll;
          }
          .circle {
            background: $poll;
          }
        }

        &.quiz {
          @include transition-delay(.1s);
          span {
            color: $quiz;
          }
          .circle {
            background: $quiz;
          }
        }

        &.personality {
          @include transition-delay(.15s);
          span {
            color: $personality;
          }
          .circle {
            background: $personality;
          }
        }

        &.contest-poll {
          @include transition-delay(.25s);
          span {
            color: $contest-poll;
          }
          .circle {
            background: $contest-poll;
          }
        }
        &.type {
          @include transition-delay(.25s);
          span {
            color: $apester;
          }
          .circle {
            background: $apester;
          }
        }


        .scale {
          @include transition(.2s ease-out);

          &:hover {
            @include scale(1.3);
          }
        }
      }

      > .create {

        &.video {
          @include transition-delay(.2s);

          .small {
            visibility: hidden;
            position: absolute;
            margin: 0;
            top: 60px;
            left: 25px;
            z-index: -1;

            .scale {
              width: 50px;

              i {
                width: 100%;
                height: 50px;
              }
            }

            &.poll {
              @include transition-delay(.05s);
              // left: -50px;
            }

            &.quiz {
              @include transition-delay(.15s);
              // left: 25px;
            }

            &.personality {
              @include transition-delay(.2s);
              // left: 92px;
            }
          }

          &:hover .small {
            visibility: visible;
            top: 162px;

            &.poll {
              left: -50px;
              top: 182px;
            }

            &.quiz {
              left: 25px;
            }

            &.personality {
              left: 92px;
            }
          }
        }

        &.video {
          span {
            color: $video;
          }

          .circle {
            background: $video;
          }

          .icon-personality {
            position: relative;
            left: 8px;
          }

        }

      }
    }
  }
}
