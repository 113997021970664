@import url(https://fonts.googleapis.com/css?family=Lato:100,300,400,700,900,100italic,300italic,400italic,700italic,900italic|Open+Sans:400italic,700italic,700,400|Roboto:400,400italic,700,700italic);
@import "./variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff2?isiww8') format('woff2'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?isiww8') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?isiww8') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?isiww8##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.ic {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-random {
  &:before {
    content: $icon-random; 
  }
}
.icon-confetti {
  &:before {
    content: $icon-confetti; 
  }
}
.icon-star {
  &:before {
    content: $icon-star; 
  }
}
.icon-intro-arrow {
  &:before {
    content: $icon-intro-arrow; 
  }
}
.icon-thumb {
  &:before {
    content: $icon-thumb; 
  }
}
.icon-hamburger {
  &:before {
    content: $icon-hamburger; 
  }
}
.icon-upload {
  &:before {
    content: $icon-upload; 
  }
}
.icon-interaction {
  &:before {
    content: $icon-interaction; 
  }
}
.icon-snippet {
  &:before {
    content: $icon-snippet; 
  }
}
.icon-tags {
  &:before {
    content: $icon-tags; 
  }
}
.icon-amp {
  &:before {
    content: $icon-amp; 
  }
}
.icon-info {
  &:before {
    content: $icon-info; 
  }
}
.icon-share {
  &:before {
    content: $icon-share; 
  }
}
.icon-like {
  &:before {
    content: $icon-like; 
  }
}
.icon-split {
  &:before {
    content: $icon-split; 
  }
}
.icon-add-collage {
  &:before {
    content: $icon-add-collage; 
  }
}
.icon-text-rtl {
  &:before {
    content: $icon-text-rtl; 
  }
}
.icon-text-ltr {
  &:before {
    content: $icon-text-ltr; 
  }
}
.icon-text-j {
  &:before {
    content: $icon-text-j; 
  }
}
.icon-text-ar {
  &:before {
    content: $icon-text-ar; 
  }
}
.icon-text-ac {
  &:before {
    content: $icon-text-ac; 
  }
}
.icon-text-al {
  &:before {
    content: $icon-text-al; 
  }
}
.icon-text-bg {
  &:before {
    content: $icon-text-bg; 
  }
}
.icon-text-l {
  &:before {
    content: $icon-text-l; 
  }
}
.icon-copy-link {
  &:before {
    content: $icon-copy-link; 
  }
}
.icon-text-q {
  &:before {
    content: $icon-text-q; 
  }
}
.icon-text-u {
  &:before {
    content: $icon-text-u; 
  }
}
.icon-text-i {
  &:before {
    content: $icon-text-i; 
  }
}
.icon-text-b {
  &:before {
    content: $icon-text-b; 
  }
}
.icon-text-h {
  &:before {
    content: $icon-text-h; 
  }
}
.icon-add-embed {
  &:before {
    content: $icon-add-embed; 
  }
}
.icon-add-image {
  &:before {
    content: $icon-add-image; 
  }
}
.icon-summary {
  &:before {
    content: $icon-summary; 
  }
}
.icon-link {
  &:before {
    content: $icon-link; 
  }
}
.icon-chapter {
  &:before {
    content: $icon-chapter; 
  }
}
.icon-question {
  &:before {
    content: $icon-question; 
  }
}
.icon-fullscreen {
  &:before {
    content: $icon-fullscreen; 
  }
}
.icon-dataroom {
  &:before {
    content: $icon-dataroom; 
  }
}
.icon-suggestions {
  &:before {
    content: $icon-suggestions; 
  }
}
.icon-print {
  &:before {
    content: $icon-print; 
  }
}
.icon-edit-pencil {
  &:before {
    content: $icon-edit-pencil; 
  }
}
.icon-password {
  &:before {
    content: $icon-password; 
  }
}
.icon-alert {
  &:before {
    content: $icon-alert; 
  }
}
.icon-minus {
  &:before {
    content: $icon-minus; 
  }
}
.icon-check {
  &:before {
    content: $icon-check; 
  }
}
.icon-download {
  &:before {
    content: $icon-download; 
  }
}
.icon-view {
  &:before {
    content: $icon-view; 
  }
}
.icon-views {
  &:before {
    content: $icon-views; 
  }
}
.icon-edit {
  &:before {
    content: $icon-edit; 
  }
}
.icon-archive {
  &:before {
    content: $icon-archive; 
  }
}
.icon-results {
  &:before {
    content: $icon-results; 
  }
}
.icon-duplicate {
  &:before {
    content: $icon-duplicate; 
  }
}
.icon-refresh {
  &:before {
    content: $icon-refresh; 
  }
}
.icon-save {
  &:before {
    content: $icon-save; 
  }
}
.icon-url {
  &:before {
    content: $icon-url; 
  }
}
.icon-computer {
  &:before {
    content: $icon-computer; 
  }
}
.icon-focus-layout {
  &:before {
    content: $icon-focus-layout; 
  }
}
.icon-versus-layout {
  &:before {
    content: $icon-versus-layout; 
  }
}
.icon-image-layout {
  &:before {
    content: $icon-image-layout; 
  }
}
.icon-text-layout {
  &:before {
    content: $icon-text-layout; 
  }
}
.icon-background {
  &:before {
    content: $icon-background; 
  }
}
.icon-undo {
  &:before {
    content: $icon-undo; 
  }
}
.icon-restart {
  &:before {
    content: $icon-restart; 
  }
}
.icon-feedback-check {
  &:before {
    content: $icon-feedback-check; 
  }
}
.icon-feedback-plus {
  &:before {
    content: $icon-feedback-plus; 
  }
}
.icon-generate {
  &:before {
    content: $icon-generate; 
  }
}
.icon-search {
  &:before {
    content: $icon-search; 
  }
}
.icon-key {
  &:before {
    content: $icon-key; 
  }
}
.icon-iframe {
  &:before {
    content: $icon-iframe; 
  }
}
.icon-embed {
  &:before {
    content: $icon-embed; 
  }
}
.icon-html5 {
  &:before {
    content: $icon-html5; 
  }
}
.icon-website {
  &:before {
    content: $icon-website; 
  }
}
.icon-wordpress {
  &:before {
    content: $icon-wordpress; 
  }
}
.icon-audio {
  &:before {
    content: $icon-audio; 
  }
}
.icon-trimmer {
  &:before {
    content: $icon-trimmer; 
  }
}
.icon-pause {
  &:before {
    content: $icon-pause; 
  }
}
.icon-play {
  &:before {
    content: $icon-play; 
  }
}
.icon-trash {
  &:before {
    content: $icon-trash; 
  }
}
.icon-trash-2 {
  &:before {
    content: $icon-trash-2; 
  }
}
.icon-circle-cross {
  &:before {
    content: $icon-circle-cross; 
  }
}
.icon-circle-check {
  &:before {
    content: $icon-circle-check; 
  }
}
.icon-duration {
  &:before {
    content: $icon-duration; 
  }
}
.icon-tag {
  &:before {
    content: $icon-tag; 
  }
}
.icon-arrow-left {
  &:before {
    content: $icon-arrow-left; 
  }
}
.icon-arrow-down {
  &:before {
    content: $icon-arrow-down; 
  }
}
.icon-arrow-up {
  &:before {
    content: $icon-arrow-up; 
  }
}
.icon-arrow-right {
  &:before {
    content: $icon-arrow-right; 
  }
}
.icon-plus {
  &:before {
    content: $icon-plus; 
  }
}
.icon-approve {
  &:before {
    content: $icon-approve; 
  }
}
.icon-cross {
  &:before {
    content: $icon-cross; 
  }
}
.icon-layers {
  &:before {
    content: $icon-layers; 
  }
}
.icon-inventory {
  &:before {
    content: $icon-inventory; 
  }
}
.icon-layouts {
  &:before {
    content: $icon-layouts; 
  }
}
.icon-cnet {
  &:before {
    content: $icon-cnet; 
  }
}
.icon-huff {
  &:before {
    content: $icon-huff; 
  }
}
.icon-email {
  &:before {
    content: $icon-email; 
  }
}
.icon-share-embed {
  &:before {
    content: $icon-share-embed; 
  }
}
.icon-instagram {
  &:before {
    content: $icon-instagram; 
  }
}
.icon-messenger {
  &:before {
    content: $icon-messenger; 
  }
}
.icon-youtube {
  &:before {
    content: $icon-youtube; 
  }
}
.icon-google-plus {
  &:before {
    content: $icon-google-plus; 
  }
}
.icon-linkedin {
  &:before {
    content: $icon-linkedin; 
  }
}
.icon-facebook {
  &:before {
    content: $icon-facebook; 
  }
}
.icon-twitter {
  &:before {
    content: $icon-twitter; 
  }
}
.icon-pinterest {
  &:before {
    content: $icon-pinterest; 
  }
}
.icon-whatsapp {
  &:before {
    content: $icon-whatsapp; 
  }
}
.icon-rolling-story-round {
  &:before {
    content: $icon-rolling-story-round; 
  }
}
.icon-countdown-round {
  &:before {
    content: $icon-countdown-round; 
  }
}
.icon-video-round {
  &:before {
    content: $icon-video-round; 
  }
}
.icon-personality-round {
  &:before {
    content: $icon-personality-round; 
  }
}
.icon-quiz-round {
  &:before {
    content: $icon-quiz-round; 
  }
}
.icon-poll-round {
  &:before {
    content: $icon-poll-round; 
  }
}
.icon-apester-round {
  &:before {
    content: $icon-apester-round; 
  }
}
.icon-royal-a-round {
  &:before {
    content: $icon-royal-a-round; 
  }
}
.icon-rolling-story {
  &:before {
    content: $icon-rolling-story; 
  }
}
.icon-countdown {
  &:before {
    content: $icon-countdown; 
  }
}
.icon-video {
  &:before {
    content: $icon-video; 
  }
}
.icon-personality {
  &:before {
    content: $icon-personality; 
  }
}
.icon-quiz {
  &:before {
    content: $icon-quiz; 
  }
}
.icon-poll {
  &:before {
    content: $icon-poll; 
  }
}
.icon-apester-a {
  &:before {
    content: $icon-apester-a; 
  }
}
.icon-royal-a {
  &:before {
    content: $icon-royal-a; 
  }
}
.icon-apester-logo {
  &:before {
    content: $icon-apester-logo; 
  }
}
.icon-sort_arrow {
  &:before {
    content: $icon-sort_arrow; 
  }
}
.icon-inject {
  &:before {
    content: $icon-inject; 
  }
}
.icon-more {
  &:before {
    content: $icon-more; 
  }
}
.icon-edit2 {
  &:before {
    content: $icon-edit2; 
  }
}
.icon-plus2 {
  &:before {
    content: $icon-plus2; 
  }
}
.icon-approve2 {
  &:before {
    content: $icon-approve2; 
  }
}
.icon-cross2 {
  &:before {
    content: $icon-cross2; 
  }
}

