@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

@mixin calc($prop, $val) {
  @each $pre in -webkit-, -moz- {
    #{$prop}: $pre + calc(#{$val});
  }
  #{$prop}: calc(#{$val});
}
