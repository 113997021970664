.campaign-header {
  margin-top: 118px;
  margin-left: 322px;
  font-size: 13px;
  text-transform: capitalize;
  display: block;
  position: relative;
  &.active {
    -webkit-animation: enter 600ms cubic-bezier(0.390, 0.575, 0.565, 1.000);
    animation: enter 600ms cubic-bezier(0.390, 0.575, 0.565, 1.000);
  }
  .campaign-types {
    .campaign-type {
      color: grey;
      cursor: pointer;
      &.active {
        color: $apester;
        font-weight: bold;
      }
    }
  }
}

@-webkit-keyframes enter {
  from {
    opacity: 0;
    margin-top: 1px;
  }
  20% {
    opacity: .15;
  }
  40% {
    opacity: .25;
    color: red;
  }
  60% {
    opacity: .5;
  }
  to {
    opacity: 1;
    margin-top: 18px;
  }
}

@keyframes enter {
  from {
    opacity: 0;
    margin-top: 1px;
  }
  20% {
    opacity: .15;
  }
  40% {
    opacity: .25;
    color: red;
  }
  60% {
    opacity: .5;
  }
  to {
    opacity: 1;
    margin-top: 18px;
  }
}