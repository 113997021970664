.video-alternative-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 70px;

     .vpb-title {
        white-space: nowrap;
        opacity: 0.5;
        position: relative;
        top: 25px;
    }
    .video-title{
        min-width: 110px;
        padding: 5px;
    }

     .vpSelect {
        width: 180px;
    }

     .setup-button {
        white-space: nowrap;
        background-color: #B9C0CB;
        height: 35px;
        position: relative;
        top: 15px;
    }


     .md-errors-spacer { min-height: 0; }

     .percentage-input{
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        font-weight: bold;
        padding: 10px;
    }

     .no-margin{
        margin:0;
    }
} 