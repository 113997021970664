.collapsable-section {
    border: solid 1px rgba(0,0,0,0.12);
    border-radius: 5px;
    position: relative;
    width:100%;

    .header {
        position: absolute;
        z-index: 1;
        top:-15px; left: -1px;
        padding-right: 10px;
        background-color: white;
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-start;
        user-select: none;

        i {
            transform: rotate(180deg);
            font-size: 10px; font-weight: bold; color: #999999;
            padding: 8px 0 0 0; 
            cursor: pointer;
            transition: 0.2s;
        }

        .title {
            font-weight: bold; font-size: 18px;
            margin-left: 10px;
        }
                
        /* sets the height of switch container */
        .switch {
            height: 26px;
            margin-left: 10px;
            md-switch {
                margin: 0;
                position: relative;
                top: 50%;
                transform: translateY(-50%);
            }
        }

    }

    .content {
        padding: 20px;
        position: relative;
        cursor: default;

        .margin {
            position: relative;
            width: 100%; 
            overflow: hidden;
            .content-height {
                width: 100%;
                position: absolute;
                bottom: 0; left:0;
                &.disabled {
                    opacity: 0.4;
                    pointer-events: none;
                    cursor: not-allowed;
                }
            }
        }

    }

    &.collapsed {
        .content {
            cursor: pointer;
        }
        .header {
            i {
                transform: rotate(0deg);
            }
        }
    }

    &.disabled {
        .header {
            .title {
                opacity: 0.4;
            }
        }
    }
    
}


