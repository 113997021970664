$duplicateError: #eb3243;

.duplicate-dialog {
  // header
  .md-toolbar-tools {
    background: white;
    color: black;
    padding: 0 15px 0 20px;
    box-shadow: 0 0 15px -5px #888;
  }

  .targeting-platforms {
    margin-bottom: 16px;
    margin-left: 20px;
  }

  // footer
  md-dialog-actions {
    padding: 0 15px 10px 0;
  }

  .duplicate-button {
    font-size: 12px;
    width: 100px;
  }

  h3 {
    margin-bottom: 0;
  }

  .duplicate-error-button {
    // style took from md documentation
    padding: 0 6px 0 6px;
    margin: 6px 8px 6px 8px;
    min-width: 88px;
    border-radius: 3px;
    font-size: 14px;
    text-align: center;
    text-transform: uppercase;
    text-decoration:none;
    border: none;
    outline: none;

    color: white;
    background-color: $duplicateError;
    height: 36px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  }

  .duplicate-error-title {
    color: $duplicateError;
  }
}
