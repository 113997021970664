.video-player-backup-input {
    display: flex;
    flex-direction: row;

    .vpb-title {
        white-space: nowrap;
        opacity: 0.5;
        position: relative;
        top: 25px;
    }

    .vpSelect {
        width: 180px;
    }

    .setup-button {
        white-space: nowrap;
        background-color: #B9C0CB;
        height: 35px;
        position: relative;
        top: 15px;
    }

    > * {
        margin-right: 20px;
    }

    .video-title{
        font-weight: bold;
    }

    .md-errors-spacer { min-height: 0; }
}