.breadcrumbs-container {
  display: inline-block;
}

.title {
  font-size: 28px;
  font-family: 'Lato';
  font-weight: bold;
  margin-left: 5px;
}

.table-message {
  padding-left: 50%;
  font-size: 30px;
  font-weight: 400;
}

.search-container {
  width: 35%;
  background-color: #ffffff;
  border-radius: 5px;
  max-height: 40px;
  md-input-container {
    width: 88%;
    margin-bottom: 0;
    padding-bottom: 0;
    input {
      border: none;
    }
  }
}

.monitoring-table {
  width: 100%;
  margin-bottom: 60px;
  .fallback{
    color: #ee2e3d;
    font-weight: bold;
  }
  a {
    text-decoration: none;
  }
  md-select:not([disabled]):focus .md-select-value {
    border-bottom-color: transparent;
    color: white;
  }
  thead {
    background: #B9C0CA;
    color: white;
  }
  th {
    padding: 10px 20px;
    white-space: nowrap;
  }
  th:first-child {
    padding-left: 15px;
    border-top-left-radius: 5px;
  }
  th:last-child {
    width: 5%;
    border-top-right-radius: 5px;
  }
  .data-edit {
    width: 56px !important;
  }
  .data-title {
    text-align: left;
    width: 27% !important;
  }
  .data-media {
    width: 13% !important;
  }
  .data-device {
    width: 25% !important;
  }
  th:nth-last-child(n+4):nth-last-child(-n+7) {
    width: 6%;
  }
  tr:not(:last-child) {
    border-bottom: 1px solid #ebebeb;
  }
  tr:hover td {
    background: #f2f2f2;
  }
  td {
    height: 50px;
    background: white;
    padding: 0 20px;
  }
  td:first-child {
    padding-left: 17px;
    max-width: 56px;
  }
  td:not(:last-child) {
    border-right: 1px solid #ebebeb;
  }
  .edit-campaign {
    color: #a8a7a7;
    text-decoration: none;
    font-size: 23px;
    &:hover,
    &:active {
      color: #35decf;
    }
  }
  .campaign-type {
    text-transform: capitalize;
  }
  .delete-campaign {
    button {
      background: #ee2e3d;
      width: 100%;
    }
  }
  .media-select,
  .device-select {
    margin: 0;
    border-bottom-color: transparent;
    .md-select-value {
      border-bottom-color: transparent;
    }
  }
  .icon-more {
    color: #a7a7a7;
    font-size: 26px;
  }
}

.report-table {
  width: 100%;
  margin-bottom: 20px;
  border-radius: 10px;
  thead {
    background: #fff;
    color:#000;
    font-size: 11px;
    text-transform: uppercase;
    line-height: 12px;
    font-weight: bold;
    font-family: "Lato";
  }
  .date-test {
    font-weight: normal;
    font-size: 12px;
    color: #404040;
    line-height: 16px;
  }
  .time-span {
    width: auto;
    margin: 0 20px 0 10px;
    position: relative;
    top: -4px;
    .circle {
      display: inline-block;
      border: 0;
      position: relative;
      width: 9px;
      height: 9px;
      background-color: #B3B3B3;
      border-radius: 50%;
      top: 4px;
      left: 5px;
    }
    .line {
      display: inline-block;
      width: 200px;
      border-bottom: 1px solid #B3B3B3;
    }
    .line-end {
      display: inline-block;
      position: relative;
      border-right: 1px solid #B3B3B3;
      height: 11px;
      right: 3px;
      top: 5px;
    }
  }
  .winnder-row {
    border: 2px solid #E5B82A;
    td {
      background: rgba(229, 184, 42, 0.05);
    }
  }
  th:first-child {
    text-align: left;
  }
  th {
    font-size: 11px;
    text-align: center;
    font-family: Lato;
    font-style: normal;
    height: 62px;
    padding: 10px 20px;
    border: 1px solid #D9D9D9;
  }
  td:first-child {
    text-align: left;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 2px;
    text-transform: uppercase;
  }
  td {
    text-align: center;
    font-family: Lato;
    font-style: normal;
    height: 62px;
    padding: 10px 20px;
    border: 1px solid #D9D9D9;
    font-size: 30px;
    line-height: 24px;
  }
}

.action-item {
  color: #a7a7a7;
  padding-left: 25px;
  font-size: 18px;
  &:hover {
    color: #a7a7a7;
  }
  &.md-focused {
    color: #a7a7a7;
  }
  i {
    margin-right: 10px;
    &.icon-cross {
      color: #eb3243;
    }
  }
}

.pagination {
  float: right;
  li a {
    color: #333333;
    background-color: #ffffff;
    border: 1px solid #ddd;
  }
  .active {
    a,
    a:hover,
    a:focus {
      color: #BABABA;
      background-color: #ffffff;
      border: 1px solid #ddd;
    }
  }
}