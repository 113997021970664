.video-yield-strategies {
    width: 100%;

    .collapsable-section:not(:last-child) {
        margin-bottom: 30px;

        .content-height::after {
            content: "";
            display: block;
            height: 30px;
        }
        .content-height::before {
            content: "";
            display: block;
            height: 10px;
        }
    }

    .full-width:after {
        content: "";
        display: block;
        clear: both;
    }

    .block-children {

        span { user-select: none; }

        > md-checkbox { display: block; }
        > md-slider-container { 
            margin-left: 30px;
            display: flex; 
            span { margin-right: 30px; }
        }

        > md-input-container {
            display: flex;
            input {
                text-align: center;
                padding-left: 15px;
                height: 50px;
                margin-top: -25px;
                margin-left: 10px;
                width: 50px;
            }
        }

        .number-input {
            border: none;
            border-bottom: solid 1px #555555;
            width: 40px;
            padding: 0 0px 0 5px;
        }

        .margin-left {
            margin-left:30px;
        }
    }


    .divider {
        background-color: rgba(0,0,0,0.12);
        width: 100%;
        height: 1px;
        margin: 30px 0;
    }

    .err-message {
        color: red;
        margin-left: 10px;              
    }

    .display-wrapper {
        border: solid 1px rgba(0, 0, 0, 0.12);
        border-radius: 5px;
        position: relative;
        width: 100%;
        padding: 30px;

        .header {
            position: absolute;
            z-index: 1;
            top:-15px; left: -1px;
            padding-right: 10px;
            background-color: white;
            font-weight: bold;
            font-size: 18px;
        }
        
        .cpi-offers-container {
            margin-top: 10px;
            
            label {
               width: 115px; 
               margin-left: 100px;
            }
            .cpi-offers-info {
                display: inline-block;
                font-size: 11px;
                color: #9FA8DA;
                position: absolute;
                margin-top: 5px;
                margin-left: 90px;

                .info-icon {
                    width: 15px;
                    height: 15px;
                    text-align: center;
                    background-color: #9FA8DA;
                    border-radius: 10px;
                    color: #FFF;
                    display: inline-block;
                    position: absolute;
                    top: 0;
                }
                .info-text {
                    display: inline-block;
                    margin-left: 20px;
                    font-style: italic;
                }
            }
            .cpi-offers-inputs {
                display: inline-block;

                tags-input {
                    display: inline-block;
                    vertical-align: middle;
                    width: 400px;
                    margin-left: 15px;
                    
                    .tags {
                        border-radius: 3px;
                    }

                    .tag-item {
                        background: #FFFFFF;
                        color: #000000;
                        font-size: 12px;
                        line-height: 1.5;
                        height: 20px;
                    }
                }
            }
        }
    }
}