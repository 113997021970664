.campaign {
  counter-reset: panel-number;

  form {
    margin-top: 100px;
  }

  h2 {
    margin-top: 0;
    margin-bottom: 20px;
  }

  .inline-flex {
    display: inline-flex;
  }

  .panel-head {
    margin-top: 30px;
    background: $panel-head;
    color: #ffffff;
    font-weight: 600;
    padding: 9px 58px;
    border-radius: 7px 7px 0 0;
    position: relative;
    overflow: hidden;

    &:before {
      counter-increment: panel-number;
      content: counter(panel-number);
      background: $panel-number;
      height: 40px;
      display: inline-block;
      padding: 9px;
      position: absolute;
      left: 0;
      top: 0;
      text-align: center;
      width: 40px;
    }
  }

  .panel-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background: #ffffff;
    padding: 30px 40px;
    margin-bottom: 20px;

    hr {
      width: 100%;
    }

    .panel-box-content-wrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }

  h5 {
    font-weight: 600;
  }

  .creative-container {
    md-input-container {
      margin-right: 13px;
    }
  }

  md-input-container {
    margin-bottom: 0;
  }

  .button-remove {
    padding: 0;
    margin-right: 8px;
    font-size: 12px;
  }

  ul {
    list-style-type: none;
    padding: 0px 4px;
  }

  li {
    color: #999999;
    font-size: 17px;
    margin-bottom: 10px;
  }

  span ul {
    height: 200px;
    width: 100%;
    border: 1px solid grey;
    border-radius: 15px;
    overflow: hidden;
    overflow-y: scroll;
  }

  .setup-button {
    color: #ffffff;
    background: $btn-primary;
    border: 0;
    border-radius: 5px;
    padding: 6px 15px;
    font-weight: 400;
    font-size: 14px;
    transition: 0.2s;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }

    &[disabled] {
      background: gray;
      color: black;
    }
  }
  .ab-test-button {
    background: $apester;
  }
  .run-button,
  .stop-button {
    background: $btn-run;
  }
  .run-button,
  .ab-test-button,
  .stop-button {
    padding: 8px 32px;
    min-width: 124px;
    height: 40px;
    text-transform: uppercase;
    .ic {
      font-size: 12px;
      margin-right: 7px;
    }
  }
  .run-button,
  .stop-button {
    position: absolute;
    right: 75px;
    width: 130px
  }
  .reletive {
    position: relative;
  }
  .show-all-button {
    width: fit-content;
    color: black;
    border: 0;
    border-radius: 5px;
    padding: 6px 15px;
    font-weight: 400;
    font-size: 14px;
    transition: 0.2s;
    cursor: pointer;
    text-decoration: underline;

    &:hover {
      opacity: 0.8;
    }
  }

  .dropdown-toggle {
    padding: 7px 15px;
  }

  .country-toggle,
  .channel-toggle {
    background: #fafafa;
    border: 1px solid #e6e6e6;
  }

  .budget-toggle {
    background: 0;
    border: 0;
    margin-right: 25px;
  }

  .schedule-container {
    .date-pickers {
      margin-top: 18px;
    }

    h5 {
      margin-left: 18px;
    }

    .md-datepicker-input-container {
      margin-left: 0;
    }
  }

  #currencySelector,
  #actionSelector {
    &.open {
      button {
        box-shadow: none;
      }
    }
  }

  .companion-platform {
    display: inline-block;
  }

  .companion-size-label {
    margin-right: 30px;
  }

  .above-unit-options {
    display: flex;
    margin-left: 12%;
    flex-direction: column;
    margin-top: -10px;
  }

  md-radio-group[disabled] {
    md-radio-button {
      .md-container {
        .md-on {
          background-color: transparent;
        }
      }
    }
  }

  .space-top {
    margin-top: 15px;
  }

  .mobile-label {
    margin-right: 35px;
  }

  .initial-steps-dropdown {
    width: 30%;
    margin-bottom: 15px;
  }

  .setup-dropdown {
    max-height: 250px;
    overflow: auto;
  }

  .update-message {
    margin-left: 7px;

    &.error {
      color: red;
    }
  }

  .glyphicon-chevron-down,
  .glyphicon-chevron-up {
    font-size: 12px;
    margin-left: 6px;
  }

  .caret {
    margin-left: 5px;
  }

  .channels-container {
    width: 25%;
  }

  .add-channel-container {
    position: relative;
    min-width: 94px;

    button {
      position: absolute;
      width: 100%;
      top: 38px;
    }
  }

  .channel-list-container {
    width: 45%;
  }

  .channel-interactions-container {
    width: 100%;

    ul {
      margin-top: 15px;
    }

    .dropdown-menu {
      margin-top: 0;
    }

    .checkbox-placeholder {
      display: inline-block;
      margin-right: 10px;
      width: 17px;
      height: 17px;

      span {
        color: #3cdd96;
      }
    }
  }

  .submit-button {
    height: 37px;
    width: 200px;
    margin-right: 15px;
  }

  .delete-button {
    height: 37px;
  }

  .campaign-field {
    display: block;
  }

  .logo-background {
    margin: auto;
    margin-bottom: 15px;
    height: 80px;
    width: 80px;
    background: #e9e9e9;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;

    & i {
      color: #b9c0cb;
      font-size: 18px;
      opacity: 0.5;
      transition: opacity 0.3s ease-out;
    }

    &:hover {
      & i {
        opacity: 1;
      }
    }

    img[src] {
      height: 100%;
      width: 100%;
      border-radius: 50%;
    }

    .campaign-logo-container {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      overflow: hidden;

      &:hover {
        .campaign-overlay {
          background: rgba(67, 67, 67, 0.9);
          opacity: 1;
        }
      }

      .campaign-overlay {
        background: rgba(0, 0, 0, 0);
        transition: background 0.3s ease-out, opacity 0.3s ease-out;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;

        & i {
          color: white;
        }
      }
    }
  }

  .consent-input {
    margin-top: 0;
    width: 150px;
    .consent-default-value {
      margin-top: 18px;
    }
    label {
      color: rgba(0, 0, 0, 0.54);
      font-family: 'Lato', 'Meoded Sans', sans-serif;
      font-weight: 700;
      font-size: 12px;
      -webkit-font-smoothing: antialiased;
      line-height: 1.42857;
      bottom: 86px;
      overflow: unset;
    }
  }
  .test-tab-container {
    background: #ffffff;
  }
  .test-tab-wrap {
    padding: 30px 40px 5px;
    margin-bottom: 10px;
  }
  .test-tab-btn {
    display: inline-block;
    margin-right: 20px;
    padding-right: 10px;
    &.active {
      font-weight: bold;
    }
    &:hover {
      cursor: pointer;
    }
    &:first-of-type {
      border-right: 2px solid #e5e5e5;
    }
    .input {
      color: #4a71d8;
      margin-left: 5px;
      border: none;
      width: 40px;
    }
  }
  .panel-test-head {
    margin: 0 40px;
    background: #B9C0CB;
    color: #ffffff;
    font-weight: 600;
    padding: 9px 20px;
    border-radius: 7px 7px 0 0;
    position: relative;
    overflow: hidden;
  }
  .alert-wrap {
    padding: 0 40px 5px;
    background: #ffffff;
  }
  .breadcrumb-wrap {
    padding: 8px 0;
    font-size: 14px;
    margin-bottom: 20px;
  }
  .breadcrumb-link {
    color: $apester;
    &:hover {
      cursor: pointer;
    }
  }
  .breadcrumb-icon {
    margin: 0 10px;
    font-size: 11px;
  }
  .panel-box-running {
    background-color: #1ca769;
    border-radius: 7px;
    color: #fff;
    & .test-btn-menu .icon-more,
    & .test-desctiption,
    & .test-status-label,
    & .icon-edit-pencil,
    & md-input-container label,
    & .test-name {
      color: #fff !important;
      background: transparent !important;
    }
    & a {
      color: #fff;
      font-weight: bold;
    }
  }
  .icon-edit-pencil {
    color: rgba(0, 0, 0, 0.54);
    font-size: 14px;
  }
  .test-name,
  .campaign-name {
    width: 350px;
    border: none !important;
    color: #333 !important;
  }
  .test-name {
    font-size: 20px;
    margin-right: 5px;
  }
  .test-btn-menu {
    border-left: 2px solid #e5e5e5;
    margin-left: 15px;
    height: 40px;
    display: inline-block;
    .icon-more {
      color: #a7a7a7;
      font-size: 26px;
    }
  }
  .popup-wrap {
    padding: 30px 20px;
    background: #fff;
    z-index: 1;
    top: 155px;
    position: absolute;
    width: 400px;
    box-shadow: 0 5px 20px #bbb;
  }
}
.test-status-label {
  color: #999999;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
}
.test-desctiption {
  margin-top: 20px;
  color: #999999;
}

.date-hyphen {
  display: inline-block;
  position: relative;
  width: 12px;
  left: 9px;
  top: 1px;
  border-bottom: solid 2px black;
}

.dropdown-menu .dropdown-search {
  display: block;
  width: 93%;
  margin: 4px auto 0 auto;
  border: 1px solid #cccccc;
  border-radius: 4px;
  padding: 3px;
}

.select-box-row {
  align-items: baseline;
}

.top-margin {
  margin: 30px 0 0 0;
}

.tag-url-wrapper {
  width: 100%;

  .tag-url-input-container {
    width: 700px;
  }
}

.radio-icon {
  margin-right: 10px;
}

.block-label {
  margin-right: 10px;
}

.sub-option {
  margin-left: 30px;
}

.channel-interaction {
  float: left;
  cursor: pointer;
  user-select: none;
  margin-right: 10px;
  max-width: 50%;
  overflow: hidden;
}

.textarea-component-wrapper {
  display: none;
  float: right;
  background: lightgray;
  padding: 5px;
  width: 40%;

  button {
    background: $btn-primary;
    color: #fff;
    min-width: 67px;
    padding: 0;
  }

  textarea {
    padding: 0 10px;
  }
}

input.toggle-embed-box {
  display: none;

  &:not([disabled]):checked + .textarea-component-wrapper {
    display: block;
  }
}

.video-targeting-setup {
  width: 100%;

  .video-setup-column {
    display: inline-block;
    width: 45%;
    vertical-align: top;

    &:first-child {
      margin-right: 9%;
    }
  }

  .channel-picker {
    margin-bottom: 30px;

    .channel-list {
      display: inline-block;
    }

    button {
      display: inline;
    }
  }

  .setup-button {
    width: 33%;
    float: right;
  }

  .inserter-wrapper {
    position: relative;

    .setup-button {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .interaction-inserter,
  .channel-list {
    width: 63%;
  }

  .black-list-picker {
    margin-top: 40px;
  }

  .blacklist-button {
    background: $apester;
  }

  .targeting-table {
    width: 100%;
    margin-left: -25px;

    th:first-child {
      width: 28px;
    }

    th:not(:first-child) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }

    td {
      padding: 7px;
      padding-left: 3px;
      vertical-align: top;
      color: rgba(0, 0, 0, 0.38);
    }

    td:first-child {
      font-size: 14px;
      cursor: pointer;
      opacity: 0;
      padding-top: 10px;
      padding-left: 5px;
    }

    td:last-child {
      max-width: 75px;
      overflow: hidden;
    }

    tbody tr:hover {
      td:not(:first-child) {
        background: rgba(215, 215, 215, 0.3);
        color: rgba(0, 0, 0, 0.38);
      }

      td:first-child {
        opacity: 1;
      }
    }

    // Put some space between the thead and the tbody
    tbody:before {
      content: '\200C';
      display: block;
      line-height: 0em;
    }
  }
}

.country-picker-wrapper {
  display: flex;
  justify-content: space-between;
  background: #f7f7f7;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 30px;
  flex-wrap: wrap;

  .country {
    display: flex;
    align-items: center;
    border-radius: 5px;
    padding: 5px 10px;
    background: white;
    margin-right: 10px;
    margin-bottom: 5px;

    .icon-cross {
      color: #b2b2b2;
      margin-right: 5px;
      font-size: 14px;
      font-weight: bolder;
    }
  }

  md-select {
    margin: 0;
  }
}

.device-picker-wrapper {
  md-checkbox {
    margin-right: 35px;
  }
}

.running-badge {
  text-transform: capitalize;
  padding: 3px 8px 5px;
  background: rgba(31, 186, 117, 0.08);
  border-radius: 2px;
  color: #1FBA75;
  font-size: 12px;
  line-height: 16px;
}

.winnder-badge {
  text-transform: uppercase;
  padding: 5px 12px;
  background: rgba(31, 186, 117, 0.08);
  border-radius: 2px;
  color: #fff;
  background-color: #E5B82A;
  font-size: 11px;
  line-height: 16px;
}

.time-badge {
  height: 24px;
  text-transform: uppercase;
  padding: 3px 8px;
  background: rgba(31, 186, 117, 0.08);
  border-radius: 2px;
  color: #fff;
  background-color: #404040;
  font-size: 11px;
  line-height: 16px;
}

.action-button {
  margin: 3px;
  display: inline-block;
  min-width: 40px;
  color: #777;
}

.action-button-wrap {
  min-width: 110px;
  align-items: center;
}

.btn-separator {
  position: relative;
  height: 25px;
  display: inline-block;
  width: 1px;
  border-right: 1px solid #ccc;
  top: 8px;
}
